<template>
  <b-form-input
    :vid="vid"
    :ref="name"
    :style="css_style"
    :value="inputVal"
    class="base-label"
    readonly
    plaintext
  />
</template>

<script>
export default {
  props: {
    value: null,
    vid: String,
    name: String,
    css_style: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.base-label:focus {
  outline: none !important;
}
</style>
